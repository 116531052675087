<script lang="ts" setup>
import type { Post } from "@internal/database";

import {
  GetBoardPostPreviewCommand,
  isShallowHttpError,
} from "@internal/server-api";
import TossFaceLocked from "@tossface/u1F512.svg?component";
import { useBoardStore } from "~~/src/stores/board";
interface Props {
  id: string;
}
const props = defineProps<Props>();
const boardStore = useBoardStore();
// 게시판이 없으면 어떻게 처리해야할지?
const currentBoard = boardStore.getBoardById(props.id);
const api = useAPI();
const router = useRouter();
let posts = [];
const isPrivate = ref(true);
const postsResponse = await useAsyncData(`get-${props.id}-posts`, () =>
  api.send(new GetBoardPostPreviewCommand({ boardId: props.id })),
);
if (
  isShallowHttpError(postsResponse.error.value) &&
  postsResponse.error.value.statusCode === 404
) {
  isPrivate.value = true;
} else {
  posts = postsResponse.data.value.posts;
  isPrivate.value = false;
}
const postsWithoutFirst = computed(() => posts.slice(1));
const goToPostPage = (post: Post): void => {
  router.push({
    path: `/board/${props.id}/post/${post.id}`,
  });
};
</script>
<template>
  <div class="board card">
    <div class="board-header">
      <span class="board-header-title">{{ currentBoard.title }}</span>
      <span
        @click="router.push({ path: `/board/${props.id}` })"
        class="board-header-more"
        >더보기</span
      >
    </div>
    <div v-if="0 < posts.length">
      <p
        @click="goToPostPage(posts[0])"
        class="board-latest-post-title text-overflow-hidden"
      >
        {{ posts[0].title }}
      </p>
      <p class="board-latest-post-datetime">
        {{ autoFormat(posts[0].createdAt, "auto") }}
      </p>
      <Divider class="divider" />
      <div class="board-post-list">
        <div
          :key="idx"
          class="board-post"
          v-for="(post, idx) of postsWithoutFirst"
        >
          <span
            @click="goToPostPage(post)"
            class="post-title text-overflow-hidden"
            >{{ post.title }}</span
          >
          <span class="post-datetime">{{
            autoFormat(post.createdAt, "auto")
          }}</span>
        </div>
      </div>
    </div>
    <div class="board--private" v-else-if="isPrivate">
      <TossFaceLocked style="width: 40px" />
      <h2>글을 볼 수 없습니다.</h2>
      <p>글을 보실려면 로그인을 하거나 특정 역할이 필요합니다.</p>
    </div>
    <div class="board-error" v-else>
      <h2>글이 존재하지 않습니다.</h2>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@mixin flex--space-between {
  display: flex;
  justify-content: space-between;
}
.board {
  width: 100%;
  height: 250px;
  padding: 16px;
  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &--private {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-header {
    @include flex--space-between;
    &-title {
      color: $text-color-grey;
    }
    &-more {
      cursor: pointer;
      color: #3273dc;
    }
  }
  &-latest-post-title {
    padding-top: 16px;
    font-size: 20px;
    cursor: pointer;
  }
  &-latest-post-datetime {
    color: $text-color-grey;
  }
  &-post {
    :first {
      padding-top: 0px;
    }
    padding-top: 8px;
    @include flex--space-between;
    .post-title {
      cursor: pointer;
    }
    .post-datetime {
      min-width: fit-content;
      color: $text-color-grey;
    }
  }
  &-error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.board-post:first-of-type {
  padding-top: 0px;
}
</style>
