<script lang="ts" setup>
import BoardPreview from "@/components/page-only/BoardPreview.vue";
import { GetStateCommand } from "@internal/server-api";
export interface Alert {
  endDate: string;
  link: string;
  startDate: string;
  text: string;
}

const title = "ERA1980";
const description = "동아대학교 전자기술학회";
useHead({
  meta: [
    {
      content: title,
      name: "title",
    },
    {
      content: description,
      name: `description`,
    },
    {
      content: title,
      name: "og:title",
    },
    {
      content: description,
      name: "og:description",
    },
  ],
  title: title,
});
const api = useAPI();
const { data: backgroundImageUrl } = await useAsyncData(
  "selected-background-image",
  () => api.send(new GetStateCommand({ key: "selected-background-image" })),
);
const { data: alert } = await useAsyncData(
  "alert",
  async (): Promise<Alert> => {
    const alertResponse = await api.send(new GetStateCommand({ key: "alert" }));
    return JSON.parse(alertResponse);
  },
);
</script>
<template>
  <WarningAlert
    :end-date="alert.endDate"
    :link="alert.link"
    :start-date="alert.startDate"
    :text="alert.text"
  />
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <div class="container">
    <nuxt-img
      :src="backgroundImageUrl"
      class="front-image"
      fetchpriority="high"
      provider="cloudinary"
      v-if="!!backgroundImageUrl"
    />
    <div class="board-list">
      <div class="board-wrapper">
        <BoardPreview :id="'notice'" />
      </div>
      <div class="board-wrapper">
        <BoardPreview :id="'introduction'" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.container {
  padding-top: 32px;
  margin: auto;
}
.front-image {
  width: 100%;
  object-fit: contain;
  border-radius: 14px;
}
.board-list {
  display: flex;
  padding-top: 32px;
  .board-wrapper {
    width: 50%;
  }
  .board-wrapper:nth-child(1) {
    padding-right: 16px;
  }
  .board-wrapper:nth-last-child(1) {
    padding-left: 16px;
  }
}
@include mobile {
  .container {
    padding-right: 8px;
    padding-left: 8px;
    margin-bottom: 16px;
  }
  .board-list {
    flex-direction: column;
    .board-wrapper {
      width: 100%;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-top: 16px;
    }
  }
}
</style>
