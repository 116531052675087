import type { Post } from "@internal/database";
import type { Axios, AxiosResponse } from "axios";

import type { Command } from "../type";
export interface GetBoardPostPreviewCommandInput {
  boardId: string;
}
export interface GetBoardPostPreviewCommandOutput {
  posts: Pick<Post, "createdAt" | "title">[];
}
export class GetBoardPostPreviewCommand
  implements Command<GetBoardPostPreviewCommandOutput>
{
  constructor(
    private readonly commandOptions: GetBoardPostPreviewCommandInput,
  ) {}
  async execute(httpClient: Axios): Promise<GetBoardPostPreviewCommandOutput> {
    const response: AxiosResponse = await httpClient.get(
      `board/${this.commandOptions.boardId}/preview`,
    );
    const result: GetBoardPostPreviewCommandOutput = response.data;
    return result;
  }
}
